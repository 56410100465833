import './App.css';
//import { render } from 'react-dom';
import React, { Component } from 'react';
import { Routes,Route } from 'react-router-dom';
import Navbar from './components/NavBar';
import Cart from './components/Cart/Cart';
import Details from './components/Details';
import ProductList from './components/ProductList';
import Default from './components/Default';
import Modal from './components/Modal'


class App extends Component {
  render() {
    return(
      <React.Fragment>
        <Navbar />
        <Routes>
            <Route exact path="/" element={<ProductList/>} />
            <Route exact path="/details" element={<Details/>} />
            <Route exact path="/cart" element={<Cart/>} />
            <Route path="*" element={<Default/>} />
        </Routes>
        <Modal />
      </React.Fragment>
    );
  }
}

export default App;
